import { createApp } from 'vue/dist/vue.esm-bundler';
import "bootstrap"

import RadarChart from '../components/RadarChart.vue'

import * as Turbo from '@hotwired/turbo'
Turbo.start()

document.addEventListener("turbo:load", function() {
  const app = createApp({
    data() {
      return {
        course: '上海自由式轮滑二队暨赛事班学员管理系统'
      }
    }
  })

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'skill_range') {
      var value = event.target.value;
      var baseUrl = "/techniques";
      switch(value) {
        case "A":
          window.location.href = baseUrl + "?q[skill_range_eq]=A";
          break;
        case "B":
          window.location.href = baseUrl + "?q[skill_range_eq]=B";
          break;
        case "C":
          window.location.href = baseUrl + "?q[skill_range_eq]=C";
          break;
        case "D":
          window.location.href = baseUrl + "?q[skill_range_eq]=D";
          break;
        case "E":
          window.location.href = baseUrl + "?q[skill_range_eq]=E";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'skill_range-user-show') {
      var value = event.target.value;
      var userId = document.getElementById('user-id').value; // 获取用户 ID
      var baseUrl = "/accounts/" + userId;
      switch(value) {
        case "A":
          window.location.href = baseUrl + "?q[skill_range_eq]=A";
          break;
        case "B":
          window.location.href = baseUrl + "?q[skill_range_eq]=B";
          break;
        case "C":
          window.location.href = baseUrl + "?q[skill_range_eq]=C";
          break;
        case "D":
          window.location.href = baseUrl + "?q[skill_range_eq]=D";
          break;
        case "E":
          window.location.href = baseUrl + "?q[skill_range_eq]=E";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'category') {
      var value = event.target.value;
      var baseUrl = "/techniques";
      switch(value) {
        case "蹲坐类":
          window.location.href = baseUrl + "?q[category_eq]=蹲坐类";
          break;
        case "旋转类":
          window.location.href = baseUrl + "?q[category_eq]=旋转类";
          break;
        case "跳跃类":
          window.location.href = baseUrl + "?q[category_eq]=跳跃类";
          break;
        case "单轮类":
          window.location.href = baseUrl + "?q[category_eq]=单轮类";
          break;
        case "组合类":
          window.location.href = baseUrl + "?q[category_eq]=组合类";
          break;
        case "其他类":
          window.location.href = baseUrl + "?q[category_eq]=其他类";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'category-user-show') {
      var value = event.target.value;
      var userId = document.getElementById('user-id').value; // 获取用户 ID
      var baseUrl = "/accounts/" + userId;
      switch(value) {
        case "蹲坐类":
          window.location.href = baseUrl + "?q[category_eq]=蹲坐类";
          break;
        case "旋转类":
          window.location.href = baseUrl + "?q[category_eq]=旋转类";
          break;
        case "跳跃类":
          window.location.href = baseUrl + "?q[category_eq]=跳跃类";
          break;
        case "单轮类":
          window.location.href = baseUrl + "?q[category_eq]=单轮类";
          break;
        case "组合类":
          window.location.href = baseUrl + "?q[category_eq]=组合类";
          break;
        case "其他类":
          window.location.href = baseUrl + "?q[category_eq]=其他类";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'level') {
      var value = event.target.value;
      var baseUrl = "/techniques";
      switch(value) {
        case "5":
          window.location.href = baseUrl + "?q[level_eq]=5";
          break;
        case "4":
          window.location.href = baseUrl + "?q[level_eq]=4";
          break;
        case "3":
          window.location.href = baseUrl + "?q[level_eq]=3";
          break;
        case "2":
          window.location.href = baseUrl + "?q[level_eq]=2";
          break;
        case "1":
          window.location.href = baseUrl + "?q[level_eq]=1";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });

  document.body.addEventListener('change', function(event) {
    if(event.target.id === 'school_district') {
      var value = event.target.value;
      var baseUrl = "/accounts";
      switch(value) {
        case "宝地":
          window.location.href = baseUrl + "?q[school_district_eq]=宝地";
          break;
        case "金桥":
          window.location.href = baseUrl + "?q[school_district_eq]=金桥";
          break;
        case "大悦城":
          window.location.href = baseUrl + "?q[school_district_eq]=大悦城";
          break;
        case "大融城":
          window.location.href = baseUrl + "?q[school_district_eq]=大融城";
          break;
        case "虎城":
          window.location.href = baseUrl + "?q[school_district_eq]=虎城";
          break;
        case "大宁":
          window.location.href = baseUrl + "?q[school_district_eq]=大宁";
          break;
        case "锦绣":
          window.location.href = baseUrl + "?q[school_district_eq]=锦绣";
          break;
        case "黄浦":
          window.location.href = baseUrl + "?q[school_district_eq]=黄浦";
          break;
        case "徐汇":
          window.location.href = baseUrl + "?q[school_district_eq]=徐汇";
          break;
        case "华泾":
          window.location.href = baseUrl + "?q[school_district_eq]=华泾";
          break;
        case "中庚":
          window.location.href = baseUrl + "?q[school_district_eq]=中庚";
          break;
        case "南丰城":
          window.location.href = baseUrl + "?q[school_district_eq]=南丰城";
          break;
        case "1088":
          window.location.href = baseUrl + "?q[school_district_eq]=1088";
          break;
        default:
          window.location.href = baseUrl;
          break;
      }
    }
  });



  app.component('radar-chart', RadarChart)
  app.mount('#app');
  console.log("app", app);
})

// Example: Load Rails libraries in Vite.
//

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
