<template>
  <!-- {{ score }} -->
  <Radar :data="computedChartData" :options="options" />
</template>

<script>
import { Radar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export default {
  name: 'App',
  components: {
    Radar
  },
  props: {
    score: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // score: this.score,
      // skillRange: [squatting, rotation, jumping, singlewheel, other, combination]
      // skillRangeA: [98, 48, 40, 19, 16, 17],
      // skillRangeB: [65, 59, 90, 81, 56, 55],
      // skillRangeC: [18, 38, 30, 9, 36, 67],
      // skillRangeD: [38, 68, 70, 89, 16, 37],
      // skillRangeE: [88, 28, 50, 79, 66, 87],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            max: 10,
            min: 0,
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    }
  },
  computed: {
    computedChartData() {
      return {
        labels: [
          '蹲坐类', // squatting
          '旋转类', // rotation
          '跳跃类', // jumping
          '单轮类', // singlewheel
          '其他类', // other
          '组合类', // combination
        ],
        datasets: [
          {
            label: 'A',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            pointBackgroundColor: 'rgba(255, 99, 132, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
            data: [
              this.score.skillRangeA.squatting.average,
              this.score.skillRangeA.rotation.average,
              this.score.skillRangeA.jumping.average,
              this.score.skillRangeA.singlewheel.average,
              this.score.skillRangeA.other.average,
              this.score.skillRangeA.combination.average,
            ]
          },
          {
            label: 'B',
            backgroundColor: 'rgba(54, 162, 235 ,0.2)',
            borderColor: 'rgba(54, 162, 235 ,1)',
            pointBackgroundColor: 'rgba(54, 162, 235 ,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235 ,1)',
            data: [
              this.score.skillRangeB.squatting.average,
              this.score.skillRangeB.rotation.average,
              this.score.skillRangeB.jumping.average,
              this.score.skillRangeB.singlewheel.average,
              this.score.skillRangeB.other.average,
              this.score.skillRangeB.combination.average,
            ]
          },
          {
            label: 'C',
            backgroundColor: 'rgba(255, 205, 86, 0.2)',
            borderColor: 'rgba(255, 205, 86, 1)',
            pointBackgroundColor: 'rgba(255, 205, 86, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 205, 86, 1)',
            data: [
              this.score.skillRangeC.squatting.average,
              this.score.skillRangeC.rotation.average,
              this.score.skillRangeC.jumping.average,
              this.score.skillRangeC.singlewheel.average,
              this.score.skillRangeC.other.average,
              this.score.skillRangeC.combination.average,
            ]
          },
          {
            label: 'D',
            backgroundColor: 'rgba(75, 192, 192,0.2)',
            borderColor: 'rgba(75, 192, 192,1)',
            pointBackgroundColor: 'rgba(75, 192, 192,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(75, 192, 192,1)',
            data: [
              this.score.skillRangeD.squatting.average,
              this.score.skillRangeD.rotation.average,
              this.score.skillRangeD.jumping.average,
              this.score.skillRangeD.singlewheel.average,
              this.score.skillRangeD.other.average,
              this.score.skillRangeD.combination.average,
            ]
          },
          {
            label: 'E',
            backgroundColor: 'rgba(201, 203, 207, 0.2)',
            borderColor: 'rgba(201, 203, 207, 1)',
            pointBackgroundColor: 'rgba(201, 203, 207, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(201, 203, 207, 1)',
            data: [
              this.score.skillRangeE.squatting.average,
              this.score.skillRangeE.rotation.average,
              this.score.skillRangeE.jumping.average,
              this.score.skillRangeE.singlewheel.average,
              this.score.skillRangeE.other.average,
              this.score.skillRangeE.combination.average,
            ]
          }
        ]
      }
    }
  }
}
</script>
